import cn from "classnames";
import { navigate } from "gatsby";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../atoms/button/Button";
import { CountryCode, CountrySubsetConfiguration } from "../../settings/countries";
import { getCookieValue, setCookie } from "../../utils/browser-features";
import { getCountriesSubset } from "../../utils/locale-configuration-utils";
import * as styles from "./CountryChangeSuggester.module.scss";

interface CountryChangeSuggesterProps {
  countryCodeIso: CountryCode;
  adoptionClassName: string;
}

const CountryChangeSuggester = ({
  countryCodeIso,
  adoptionClassName,
}: CountryChangeSuggesterProps): ReactElement => {
  const [show, setShow] = useState(true);
  const [visitorCountry, setVisitorCountry] = useState<string | undefined>("");
  const { t } = useTranslation();
  const countries: CountrySubsetConfiguration[] = getCountriesSubset();
  const countryOptions = countries.filter(({ countryCode }) => countryCode !== countryCodeIso);

  const showCountrySuggester =
    show &&
    countryCodeIso === "ES" &&
    countryOptions.some(({ countryCode }) => countryCode === visitorCountry) &&
    getCookieValue("default_landing") === "";

  const handleStay = () => {
    setCookie("default_landing", "ES", "/");
    setShow(false);
  };

  const handleNavigate = (defaultLocale: string) => {
    setCookie("default_landing", defaultLocale, "/");
    void navigate(`/${defaultLocale}/`);
  };

  useEffect(() => {
    setVisitorCountry(getCookieValue("visitor_country"));
  }, []);

  return showCountrySuggester ? (
    <div className={cn(styles.backDrop, adoptionClassName)}>
      <aside className={styles.countrySuggesterSwitch}>
        {countryOptions
          .filter(({ countryCode }) => visitorCountry === countryCode)
          .map(({ defaultLocale }) => (
            <>
              <p>{t("country_suggester.description", { lng: defaultLocale })}</p>
              <Button type="button" onClick={() => handleStay()}>
                {t("country_suggester.stay", { lng: defaultLocale })}
              </Button>
              <Button type="button" onClick={() => handleNavigate(defaultLocale)}>
                {t("country_suggester.navigate_to_landing", { lng: defaultLocale })}
              </Button>
            </>
          ))}
      </aside>
    </div>
  ) : (
    <></>
  );
};

export default CountryChangeSuggester;
