import {
  getCompadoTrackingIdFromCookie,
  setCompadoTrackingId,
} from "../repository/compado-tracking-repository";

export const useSetCompadoTracking = (): ((trackingId: string) => void) => {
  return (trackingId: string): void => setCompadoTrackingId(trackingId);
};

export const useCompadoTrackingFromCookie = (): string | undefined => {
  return getCompadoTrackingIdFromCookie();
};
